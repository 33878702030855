import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const labelStyle = {
  fontWeight: '600',
  fontSize: '22px',
  marginLeft: '2px',
};

const checkboxStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '36px',
  marginTop: '10px',
  marginBottom: '10px',
};

export default (props) => {
  const onChange = (value) => {
    props.input.onChange(!props.input.value);
  };

  return (
    <div style={checkboxStyle}>
      <Checkbox
        {...props}
        onChange={onChange}
        checked={props.input.value}
        label={{ children: '📦 DropShip', style: labelStyle }}
      />
    </div>
  );
};
