import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { MoneyInput } from './customComponents';
import NumberFormat from 'react-number-format';
import { Table, Input } from 'semantic-ui-react';
import CustomerAddressMenuItem from './CustomerAddressMenuItem';
import Axios from '../../../util/CustomAxios';

const propTypes = {};

const defaultProps = {};

class CustomerAddressChangeMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };

    this.onAddressSelect = this.onAddressSelect.bind(this);
  }

  onAddressSelect(address) {
    this.props.onAddressSelect();
  }

  addressSorter(a, b) {
    if (a.active == b.active) {
      return 0;
    }
    if (a.active && !b.active) {
      return -1;
    }
    return 1;
  }

  activateAddress(id) {
    Axios.put('/Address/');
  }

  onSearchChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { addressList, onAddressSelect } = this.props;

    const searchValue = this.state.searchValue;
    const addresses = (addressList || [])
      .filter(
        (address) =>
          (address.address.toLowerCase().includes(searchValue.toLowerCase()) ||
            address.postal_code
              .toLowerCase()
              .includes(searchValue.toLowerCase())) &&
          !!address.active // active가 false인 것은 보여주지 않음
      )
      .sort(this.addressSorter);

    const addressItems = addresses.map((item) => (
      <CustomerAddressMenuItem
        {...item}
        key={item.id}
        onClick={() => onAddressSelect(item)}
      />
    ));
    return (
      <div className="CustomerAddress__Edit--Container">
        <Input
          style={{ width: '100%', hieght: '30px' }}
          value={searchValue}
          onChange={this.onSearchChange}
          name="searchValue"
        />
        <div className="CustomerAddress__Edit--Scroller">{addressItems}</div>
      </div>
    );
  }
}

CustomerAddressChangeMenu.PropTypes = propTypes;
CustomerAddressChangeMenu.defaultProps = defaultProps;

export default CustomerAddressChangeMenu;
