import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ProductSelector from '../presenters/ProductSelector';
import ProductOrderList from '../presenters/ProductOrderList';
import { getFormValues } from 'redux-form';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getProductList,
  addOrderProduct,
  deleteOrderProduct,
  setDiscount,
  setCustomDiscount,
  setSettingDiscount,
  setQuantity,
  setInitialProductOrderList,
} from '../../../action/product';
import { setVoltage } from '../../../action/customer';
import { productList, discount } from '../../../reducer/product';

import SalesDiscountSetting from '../SalesDiscountSetting';
import SalesAmountSetting from '../SalesAmountSetting';

const propTypes = {};

const defaultProps = {};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class Product extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.currencyExceptionHandler = this.currencyExceptionHandler.bind(this);
    this.discountExceptionHandler = this.discountExceptionHandler.bind(this);
    this.amountExceptionHandler = this.amountExceptionHandler.bind(this);
    this.calculateDiscountFromAmount =
      this.calculateDiscountFromAmount.bind(this);
    this.setRate = this.setRate.bind(this);
  }

  currencyExceptionHandler() {
    if (this.props.selectedCustomer.Currency == undefined) return 'USD';
    return this.props.selectedCustomer.Currency;
  }

  discountSetting(nextProps) {
    const total = nextProps.productOrderList.reduce((a, b) => {
      return a + b.product.USDRetail * b.quantity;
    }, 0);

    if (total >= 60000) return 60;
    else if (total >= 40000 && total < 60000) return 55;
    else if (total >= 20000 && total < 40000) return 52;
    else if (total >= 10000 && total < 20000) return 50;
    else if (total >= 0 && total < 10000) return 47;
    else return 47;
  }

  discountExceptionHandler(discount, nextProps) {
    let Discount = discount;

    if (nextProps.company == 'Aurender Inc.') {
      if (nextProps.selectedCustomer.AccountID != undefined) {
        SalesDiscountSetting.AurenderInc.byCountry.forEach((country) => {
          if (nextProps.billingCountry == country.Country)
            Discount = country.Discount;
        });

        SalesDiscountSetting.AurenderInc.byCompany.forEach((company) => {
          if (nextProps.selectedCustomer.AccountID == company.Company)
            Discount = company.Discount;
        });
      }
      return Discount;
    } else if (nextProps.company == 'Aurender America Inc.') {
      Discount = SalesDiscountSetting.AurenderAmericaInc.Discount;
      return Discount;
    } else return Discount;
  }

  updateProductOrdersForSalesOrderType(salesOrderType) {
    this.props.productOrderList.forEach((productOrder) => {
      const newQuantity =
        salesOrderType === 8
          ? -Math.abs(productOrder.quantity)
          : Math.abs(productOrder.quantity);
      if (newQuantity !== productOrder.quantity) {
        this.props.setQuantity(productOrder.id, newQuantity);
      }
    });
  }

  amountExceptionHandler(nextProps) {
    if (this.props.company == 'Aurender Inc.') {
      if (nextProps.selectedCustomer.AccountID != undefined) {
        SalesAmountSetting.AurenderInc.byCountry.forEach((country) => {
          if (country.Country == nextProps.billingCountry) {
            nextProps.productOrderList.forEach((productOrder) => {
              country.Prices.forEach((product) => {
                if (
                  product.Model == productOrder.product.Model.trim() &&
                  product.HDD == productOrder.product.HDD.trim()
                ) {
                  if (productOrder.product.Model.trim() == 'N100C') {
                    if (product.SSD == productOrder.product.SSD.trim()) {
                      let discount = 0;
                      discount = this.calculateDiscountFromAmount(
                        productOrder,
                        this.currencyExceptionHandler(),
                        product.Amount
                      );
                      if (
                        discount != productOrder.discount &&
                        productOrder.isCustomDiscount == false
                      )
                        this.props.setSettingDiscount(
                          productOrder.id,
                          discount
                        );
                    }
                  } else {
                    let discount = 0;
                    discount = this.calculateDiscountFromAmount(
                      productOrder,
                      this.currencyExceptionHandler(),
                      product.Amount
                    );
                    if (
                      discount != productOrder.discount &&
                      productOrder.isCustomDiscount == false
                    )
                      this.props.setSettingDiscount(productOrder.id, discount);
                  }
                }
              });
            });
          }
        });

        SalesAmountSetting.AurenderInc.byCompany.forEach((company) => {
          if (company.Company == nextProps.selectedCustomer.AccountID) {
            nextProps.productOrderList.forEach((productOrder) => {
              company.Prices.forEach((product) => {
                if (
                  product.Model == productOrder.product.Model.trim() &&
                  product.HDD == productOrder.product.HDD.trim()
                ) {
                  if (productOrder.product.Model.trim() == 'N100C') {
                    if (product.SSD == productOrder.product.SSD.trim()) {
                      let discount = 0;
                      discount = this.calculateDiscountFromAmount(
                        productOrder,
                        this.currencyExceptionHandler(),
                        product.Amount
                      );
                      if (
                        discount != productOrder.discount &&
                        productOrder.isCustomDiscount == false
                      )
                        this.props.setSettingDiscount(
                          productOrder.id,
                          discount
                        );
                    }
                  } else {
                    let discount = 0;
                    discount = this.calculateDiscountFromAmount(
                      productOrder,
                      this.currencyExceptionHandler(),
                      product.Amount
                    );
                    if (
                      discount != productOrder.discount &&
                      productOrder.isCustomDiscount == false
                    )
                      this.props.setSettingDiscount(productOrder.id, discount);
                  }
                }
              });
            });
          }
        });
      }
    } else if (this.props.company == 'Aurender America Inc.') {
    }
  }

  calculateDiscountFromAmount(productOrder, currency, amount) {
    let discount = 100 - (amount / this.setRate(productOrder, currency)) * 100;
    return discount;
  }

  setRate(productOrder, currency) {
    if (currency.trim() == 'USD') return productOrder.product.USDRetail;
    else if (currency.trim() == 'KRW') return productOrder.product.KRWRetail;
    else return productOrder.product.USDRetail;
  }

  componentDidMount() {
    this.props.getProductList();

    // Aurender America Inc. 회사인 경우 voltage를 120V로 강제 설정
    if (this.props.company === 'Aurender America Inc.') {
      this.props.setVoltage('120V');
    }
  }

  componentWillReceiveProps(nextProps) {
    const resetAllItem = true;
    let discount = 0;
    if (nextProps.productOrderList.length > 0) {
      discount = this.discountExceptionHandler(
        this.discountSetting(nextProps),
        nextProps
      );
      if (discount != this.props.discount) this.props.setDiscount(discount);
      this.amountExceptionHandler(nextProps);
    }

    // Check if salesOrderType has changed
    if (this.props.salesOrderType !== nextProps.salesOrderType) {
      this.updateProductOrdersForSalesOrderType(nextProps.salesOrderType);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isInvoice ? null : (
          <ProductSelector
            productList={this.props.productList}
            onAdd={this.props.addOrderProduct}
            discount={this.props.discount}
            voltage={this.props.voltage}
            selectedCustomer={this.props.selectedCustomer}
            company={this.props.company}
          />
        )}

        <ProductOrderList
          productOrderList={this.props.productOrderList}
          selectedCustomer={this.props.selectedCustomer}
          discount={this.props.discount}
          deleteOrderProduct={this.props.deleteOrderProduct}
          setDiscount={this.props.setDiscount}
          setCustomDiscount={this.props.setCustomDiscount}
          setQuantity={this.props.setQuantity}
          billingCountry={this.props.billingCountry}
          salesOrderType={this.props.salesOrderType}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    productList: state.productList,
    productOrderList: state.productOrderList,
    discount: state.discount,
    selectedCustomer: state.selectedCustomer.billingCustomer,
    billingCountry: state.billingCountry,
    voltage: state.voltage,
    company: state.company,
    salesOrderType: getFormValues('SalesOrder')(state)?.SOType,
  }),
  (dispatch) => ({
    getProductList: bindActionCreators(getProductList, dispatch),
    addOrderProduct: bindActionCreators(addOrderProduct, dispatch),
    deleteOrderProduct: bindActionCreators(deleteOrderProduct, dispatch),
    setDiscount: bindActionCreators(setDiscount, dispatch),
    setCustomDiscount: bindActionCreators(setCustomDiscount, dispatch),
    setSettingDiscount: bindActionCreators(setSettingDiscount, dispatch),
    setQuantity: bindActionCreators(setQuantity, dispatch),
    setInitialProductOrderList: bindActionCreators(
      setInitialProductOrderList,
      dispatch
    ),
    setVoltage: bindActionCreators(setVoltage, dispatch),
    dispatch,
  })
)(Product);

Product.PropTypes = propTypes;
Product.defaultProps = defaultProps;
