import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import axios from '../../../util/CustomAxios';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';

import { BallLoader } from '../../General/BallLoader';
import '../SalesOrder.css';

class ButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      createdSOID: -1,
      isLoading: false,
    };
    this.submitButtonOnClickHandler =
      this.submitButtonOnClickHandler.bind(this);
  }
  submitButtonOnClickHandler(event, data) {
    // if it is dropship, check if the note is empty
    if (
      !!this.props.SalesOrderFormData.DropShip &&
      !this.props.SalesOrderFormData.Note
    ) {
      alert('Note is required when DropShip is checked');
      return;
    }

    for (let error in this.props.SalesOrderFormDataError) {
      console.log(error);

      if (this.props.SalesOrderFormDataError[error] != '') {
        return;
      }
    }

    const SalesOrderData = {
      ...this.props.SalesOrderFormData,
      AccountID: this.props.SalesOrderFormData.billingAccountID,
      Status: 4,
      Payment: null,
      DateofShipment: null,
      DateofPickUp: null,
      Currency: this.props.currency.trim(),
      Amount:
        this.props.salesOrderType === 8
          ? -Math.abs(
              Number(this.props.subTotal) +
                Number(this.props.SalesOrderFormData.ShippingCharge) +
                Number(this.props.SalesOrderFormData.Adjustment)
            )
          : Number(this.props.subTotal) +
            Number(this.props.SalesOrderFormData.ShippingCharge) +
            Number(this.props.SalesOrderFormData.Adjustment),
      Company: this.props.company,
      SODetailes: this.props.SODetailes,
      DropShipContactId: this.props.SalesOrderFormData.DropShipContactId,
    };

    console.log('SalesOrderData', SalesOrderData);

    this.setState({
      isLoading: true,
    });

    if (this.props.isEdit) {
      axios
        .put(`/SalesOrder/${this.props.SOID}`, SalesOrderData)
        .then((response) => {
          this.setState({
            isSubmited: true,
            createdSOID: this.props.SOID,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false,
          });
          alert('에러가 발생했습니다. 담당자에게 문의해주세요.');
        });
    } else {
      console.log('SalesOrderData', SalesOrderData);
      axios
        .post('/SalesOrder/', SalesOrderData)
        .then(async (response) => {
          this.setState({
            isSubmited: true,
            createdSOID: response.data.SOID,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false,
          });
          alert('에러가 발생했습니다. 담당자에게 문의해주세요.');
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? <BallLoader /> : null}
        {this.state.isSubmited ? (
          <Redirect to={`/SalesOrder/${this.state.createdSOID}`} />
        ) : null}
        <Grid.Row stretched style={this.props.style}>
          <Grid.Column width={10} verticalAlign="middle"></Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <Link
              to={
                this.props.isEdit
                  ? `/SalesOrder/${this.props.SOID}`
                  : '/SalesOrder'
              }
            >
              <Button size="big" className="CancelButton">
                Cancel
              </Button>
            </Link>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            {/* <button type="submit">Submit</button> */}
            <button
              class="big ui button"
              type="submit"
              onClick={this.submitButtonOnClickHandler}
              disabled={this.state.isSubmited || this.state.isLoading}
            >
              {this.props.isEdit ? 'Save' : 'Submit'}
            </button>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  currency: !!state.selectedCustomer.billingCustomer.Currency
    ? state.selectedCustomer.billingCustomer.Currency.trim()
    : 'USD',
  selectedCustomer: state.selectedCustomer.billingCustomer,
  subTotal: state.productOrderList.reduce((a, b) => {
    const quantity =
      state.SalesOrderFormData?.SOType === 8
        ? -Math.abs(b.quantity)
        : b.quantity;
    if (
      state.selectedCustomer.billingCustomer.Currency != undefined &&
      state.selectedCustomer.billingCustomer.Currency.trim() == 'KRW'
    ) {
      return (
        a +
        b.product.KRWRetail *
          Number(quantity) *
          ((100 - Number(b.discount)) / 100)
      );
    }
    return (
      a +
      b.product.USDRetail *
        Number(quantity) *
        ((100 - Number(b.discount)) / 100)
    );
  }, 0),
  SalesOrderFormData: !!getFormValues('SalesOrder')(state)
    ? getFormValues('SalesOrder')(state)
    : null,
  SalesOrderFormDataError: getFormSyncErrors('SalesOrder')(state),
  SODetailes: state.productOrderList.map((productOrder) => {
    const salesOrderType = state.SalesOrderFormData?.SOType;
    const quantity =
      salesOrderType === 8
        ? -Math.abs(productOrder.quantity)
        : productOrder.quantity;
    const rate =
      state.selectedCustomer.billingCustomer.Currency == undefined ||
      state.selectedCustomer.billingCustomer.Currency.trim() == 'USD'
        ? productOrder.product.USDRetail
        : productOrder.product.KRWRetail;
    const amount =
      rate * Number(quantity) * ((100 - Number(productOrder.discount)) / 100);

    return {
      ProductID:
        productOrder.product.ProductID || productOrder.product.ProductId,
      Model: productOrder.product.Model || productOrder.product.Model,
      ProductCode:
        productOrder.product.ProductCode || productOrder.product.ProductCode,
      Color: productOrder.product.Color || productOrder.product.Color,
      Currency:
        state.selectedCustomer.billingCustomer.Currency == undefined
          ? 'USD'
          : state.selectedCustomer.billingCustomer.Currency.trim(),
      Rate: rate,
      Quantity: quantity,
      Discount: productOrder.discount,
      Amount: amount,
      IsCustomDiscount: productOrder.isCustomDiscount,
      Serial: null,
      SOIID: productOrder.SOIID,
    };
  }),
  shippingCustomerID: state.shippingCustomerID,
  company: state.company,
  salesOrderType: getFormValues('SalesOrder')(state)?.SOType,
}))(ButtonContainer);
