import axios from '../util/CustomAxios';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const INSERT_ADDRESS = 'INSERT_ADDRESS';
export const SET_EDIT_MODE_ADDRESS = 'SET_EDIT_MODE_ADDRESS';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';

export function setAccountList(accountList) {
  return {
    type: SET_ACCOUNT_LIST,
    accountList: accountList,
  };
}

export function addAddress(address, accountId) {
  return {
    type: ADD_ADDRESS,
    addressList: address,
    accountId: accountId,
  };
}

export function insertAddress(address) {
  return {
    type: INSERT_ADDRESS,
    address: address,
  };
}

export function setEditModeAddress(address) {
  return {
    type: SET_EDIT_MODE_ADDRESS,
    address: address,
  };
}

export function changeAccount(changedPart, accountId) {
  return {
    type: CHANGE_ACCOUNT,
    changedPart: changedPart,
    accountId: accountId,
  };
}

export const getAccountList = (state) => (dispatch) => {
  return axios
    .get('/Account')
    .then((response) => {
      dispatch(setAccountList(response.data));
    })
    .catch(function (error) {});
};

export const getAddress = (AccountID) => (dispatch) => {
  return axios
    .get('/Address/' + AccountID)
    .then((response) => {
      dispatch(addAddress(response.data, AccountID));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const insertAddressRequest = (address) => (dispatch) => {
  const addressData = {
    ...address,
    is_default_shipping: address.is_default_shipping || false,
    is_default_billing: address.is_default_billing || false,
    active: address.active ?? true,
  };

  return axios
    .post('/Address', addressData)
    .then((response) => {
      const { newAddresses, newAddressId } = response.data;
      dispatch(addAddress(newAddresses, newAddresses[0].account_id));
      return {
        newAddresses,
        newAddressId,
      };
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
};

export const updateAddressRequest = (address, addressId) => (dispatch) => {
  return axios
    .put('/Address/' + addressId, address)
    .then((response) => {
      dispatch(addAddress(response.data, response.data[0].account_id));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const patchAddressRequest = (address, addressId) => (dispatch) => {
  return axios
    .patch('/Address/' + addressId, address)
    .then((response) => {})
    .catch(function (error) {
      console.log(error);
    });
};

export const patchAccountRequest =
  (account, accountId, onPatchSuccess) => (dispatch) => {
    return axios
      .patch('/Account/' + accountId, account)
      .then((response) => {
        dispatch(changeAccount(account, accountId));
        onPatchSuccess(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const deleteAddress = (addressId, accountId) => (dispatch) => {
  return axios
    .delete('/Address/' + accountId + '/' + addressId)
    .then((response) => {
      console.log(114, response.data);
      dispatch(addAddress(response.data, accountId));
    })
    .catch(function (error) {
      console.error(error);
      alert(error.response.data);
    });
};
