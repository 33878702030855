import React, { Component } from 'react';
import PropTypes, { node } from 'prop-types';
import { Dropdown, Button } from 'semantic-ui-react';
import ProductSuggestBoxItem from './ProductSuggestBoxItem';

const propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      ProductCode: PropTypes.string,
      ProductID: PropTypes.number.isRequired,
      ModelT: PropTypes.string,
      Model: PropTypes.string,
      Color: PropTypes.string,
      Voltage: PropTypes.string,
      HDD: PropTypes.string,
      SSD: PropTypes.string,
      USDRetail: PropTypes.number,
      KRWRetail: PropTypes.number,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  company: PropTypes.string,
};

const defaultProps = {
  productList: [],
  onSelect: createWarning('onItemClick'),
  company: '',
};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

export default class ProductSuggestDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: '',
      color: '',
      voltage: props.company === 'Aurender America Inc.' ? '120V' : '',
      hdd: '',
      ssd: '',
      item: '',
      modelOrigin: [],
      modelList: [],
      colorList: [],
      voltageList: [],
      hddList: [],
      ssdList: [],
      itemList: [],
      isWork: false,
      isChange: false,
      isFirst: true,
      isCustomVoltage: props.company === 'Aurender America Inc.',
      voltageReverse: false,
      isWrongSelect: false,
      onAddFocusModel: false,
      isCSParts: false,
    };

    this.dropDownOnChangeHandler = this.dropDownOnChangeHandler.bind(this);
    this.dropDownOnFocusHandler = this.dropDownOnFocusHandler.bind(this);
    this.dropDownOnCloseHandler = this.dropDownOnCloseHandler.bind(this);
    this.resetButtonOnClickHandler = this.resetButtonOnClickHandler.bind(this);
    this.makeArrayToDropDownOptionFormat =
      this.makeArrayToDropDownOptionFormat.bind(this);
    this.setStateListsFromCurrentProductList =
      this.setStateListsFromCurrentProductList.bind(this);
  }

  componentDidMount() {
    // Set voltage to 120V for Aurender America Inc. when component mounts
    if (
      this.props.company === 'Aurender America Inc.' &&
      this.state.voltage !== '120V'
    ) {
      this.setState({
        voltage: '120V',
        isCustomVoltage: true,
      });
    }
  }

  dropDownOnChangeHandler(event, data) {
    if (data.name == 'model') {
      // 회사가 Aurender America Inc.인 경우 voltage 기본값 설정
      const voltage =
        this.state.voltage ||
        (this.props.company === 'Aurender America Inc.' ? '120V' : '');
      const isCustomVoltage = this.state.isCustomVoltage;

      if (data.value == 'CS_Parts') {
        this.setState({
          model: data.value,
          color: '',
          voltage: voltage,
          hdd: '',
          ssd: '',
          isChange: true,
          isCustomVoltage: isCustomVoltage,
          isCSParts: true,
        });
      } else {
        this.setState({
          model: data.value,
          color: '',
          voltage: voltage,
          hdd: '',
          ssd: '',
          isChange: true,
          isCustomVoltage: isCustomVoltage,
          isCSParts: false,
        });
      }
    } else {
      if (data.name == 'voltage') {
        this.setState({
          [data.name]: data.value,
          isChange: true,
          isCustomVoltage: true,
        });
      }
      this.setState({
        [data.name]: data.value,
        isChange: true,
      });
    }
  }

  dropDownOnFocusHandler(event, data) {
    // 모든 필드에 대한 일반적인 처리
    this.setState({
      [data.name]: '',
      isWork: true,
    });
    if (data.name == 'model') {
      this.setState({
        isCSParts: false,
      });
    }
  }

  dropDownOnCloseHandler(event, data) {
    this.setState({
      isWork: true,
    });
  }

  resetButtonOnClickHandler(event, data) {
    // Don't reset voltage to empty if company is Aurender America Inc.
    const voltage =
      this.props.company === 'Aurender America Inc.' ? '120V' : '';
    const isCustomVoltage = this.props.company === 'Aurender America Inc.';

    this.setState({
      model: '',
      color: '',
      voltage: voltage,
      hdd: '',
      ssd: '',
      item: '',
      isWork: true,
      isCustomVoltage: isCustomVoltage,
      isCSParts: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isFirst) {
      this.setStateListsFromCurrentProductList(nextProps.productList);
    }
    if (nextProps.isAdded) {
      this.resetButtonOnClickHandler();
      this.setState({
        onAddFocusModel: true,
      });
    }
    if (this.props.selectedCustomer != nextProps.selectedCustomer) {
      this.resetButtonOnClickHandler();
    }

    // Check if the company is Aurender America Inc. and set voltage to 120V
    if (nextProps.company === 'Aurender America Inc.') {
      this.setState({
        voltage: '120V',
        isCustomVoltage: true,
      });
    } else if (
      nextProps.voltage.trim() != '' &&
      this.state.isCustomVoltage != true
    ) {
      if (this.state.voltage != nextProps.voltage.trim()) {
        if (this.state.voltage != 'Free Voltage') {
          this.setState({
            voltage: nextProps.voltage.trim(),
          });
        }
      }
    }
    if (nextProps.isWrongSelect != this.state.isWrongSelect) {
      this.setState({
        isWrongSelect: nextProps.isWrongSelect,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isWork) {
      const nextProductList = nextProps.productList.filter((product) => {
        if (
          nextState.model != '' &&
          (product.Model ? product.Model.trim() != nextState.model : false)
        )
          return false;
        if (
          nextState.color != '' &&
          (product.Color
            ? product.Color.trim() !== nextState.color.trim()
            : false)
        )
          return false;
        if (
          nextState.voltage != '' &&
          (product.Voltage
            ? product.Voltage.trim() != nextState.voltage
            : false)
        )
          return false;
        if (
          nextState.hdd != '' &&
          (product.HDD ? product.HDD.trim() !== nextState.hdd.trim() : false)
        )
          return false;
        if (
          nextState.ssd != '' &&
          (product.SSD ? product.SSD.trim() !== nextState.ssd.trim() : false)
        )
          return false;
        if (
          nextState.item != '' &&
          (product.Item
            ? product.Item.trim() !== nextState.item.trim()
            : false) &&
          product.Model.trim() == 'CS_Parts'
        )
          return false;
        return true;
      });
      this.setStateListsFromCurrentProductList(nextProductList);
      if (nextState.isChange) nextProps.onSelect(nextProductList);

      const isAurenderAmerica = nextProps.company === 'Aurender America Inc.';
      const voltage = isAurenderAmerica ? '120V' : nextProps.voltage.trim();
      const voltageReverse = isAurenderAmerica
        ? false
        : nextProps.voltage.trim() != ''
          ? nextProps.voltage.trim() == '120V'
            ? false
            : true
          : false;

      this.setState({
        isWork: false,
        isChange: false,
        voltageReverse: voltageReverse,
      });

      // 항상 Aurender America Inc.에 대한 voltage 설정 확인
      if (isAurenderAmerica && nextState.voltage !== '120V') {
        this.setState({
          voltage: '120V',
          isCustomVoltage: true,
        });
      }
    }
    return true;
  }

  makeArrayToDropDownOptionFormat(array, order) {
    var returnArray = array.sort().map((item, index) => {
      return {
        key: index,
        text: item,
        value: item,
      };
    });
    if (order == 0) returnArray.reverse();
    return returnArray;
  }

  setStateListsFromCurrentProductList(currentProductList) {
    var model = [];
    var color = [];
    var voltage = [];
    var hdd = [];
    var ssd = [];
    var item = [];

    currentProductList.forEach((product) => {
      product.Model ? model.push(product.Model.trim()) : null;
      product.Color ? color.push(product.Color.trim()) : null;
      product.Voltage ? voltage.push(product.Voltage.trim()) : null;
      product.HDD ? hdd.push(product.HDD.trim()) : null;
      product.SSD ? ssd.push(product.SSD.trim()) : null;
      product.Item ? item.push(product.Item.trim()) : null;
    });

    model = model.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    color = color.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    voltage = voltage.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    hdd = hdd.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    ssd = ssd.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    item = item.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    // Aurender America Inc. 회사인 경우 항상 120V를 voltage 목록에 포함
    if (
      this.props.company === 'Aurender America Inc.' &&
      voltage.indexOf('120V') === -1
    ) {
      voltage.push('120V');
    }

    let voltageList = this.makeArrayToDropDownOptionFormat(voltage, 1);

    // 회사가 Aurender America Inc.인 경우에 사용할 voltage 값
    const americaVoltage =
      this.props.company === 'Aurender America Inc.' ? '120V' : '';
    const isCustomVoltage = this.props.company === 'Aurender America Inc.';

    if (this.state.isFirst || this.state.modelOrigin.length < 1) {
      this.setState({
        modelOrigin: this.makeArrayToDropDownOptionFormat(model, 1),
        modelList: this.makeArrayToDropDownOptionFormat(model, 1),
        colorList: this.makeArrayToDropDownOptionFormat(color, 1),
        voltageList: voltageList,
        hddList: this.makeArrayToDropDownOptionFormat(hdd, 0),
        ssdList: this.makeArrayToDropDownOptionFormat(ssd, 1),
        itemList: this.makeArrayToDropDownOptionFormat(item, 1),
        isFirst: false,
      });
    } else {
      this.setState({
        modelList: this.makeArrayToDropDownOptionFormat(model, 1),
        colorList: this.makeArrayToDropDownOptionFormat(color, 1),
        voltageList: voltageList,
        hddList: this.makeArrayToDropDownOptionFormat(hdd, 0),
        ssdList: this.makeArrayToDropDownOptionFormat(ssd, 1),
        itemList: this.makeArrayToDropDownOptionFormat(item, 1),
      });
    }
    if (model.length == 1) {
      this.setState({
        model: model[0],
      });
    }

    if (color.length == 1) {
      this.setState({
        color: color[0],
      });
    }

    // voltage 처리 로직 수정 - Aurender America Inc.일 경우 항상 120V
    if (this.props.company === 'Aurender America Inc.') {
      this.setState({
        voltage: '120V',
        isCustomVoltage: true,
      });
    } else if (voltage.length == 1) {
      this.setState({
        voltage: voltage[0],
      });
    }

    if (hdd.length == 1) {
      this.setState({
        hdd: hdd[0],
      });
    }
    if (ssd.length == 1) {
      this.setState({
        ssd: ssd[0],
      });
    }
    if (item.length == 1) {
      this.setState({
        item: item[0],
      });
    }
  }

  render() {
    return this.state.isCSParts ? (
      <div style={{ display: 'inherit' }}>
        <Dropdown
          className="product__selector"
          focus={this.state.onAddFocusModel ? true : null}
          name="model"
          value={this.state.model}
          placeholder="Model"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={this.state.modelOrigin}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.model == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Dropdown
          className="product__selector"
          name="item"
          value={this.state.item}
          placeholder="Item"
          fluid
          search
          selection
          style={{ width: '80%' }}
          options={this.state.itemList}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.item == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Button
          style={{ width: '5%', marginLeft: '10px', padding: '0px' }}
          onClick={this.resetButtonOnClickHandler}
        >
          X
        </Button>
      </div>
    ) : (
      <div style={{ display: 'inherit' }}>
        <Dropdown
          className="product__selector"
          focus={this.state.onAddFocusModel ? true : null}
          name="model"
          value={this.state.model}
          placeholder="Model"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={this.state.modelOrigin}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.model == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Dropdown
          className="product__selector"
          name="color"
          value={this.state.color}
          placeholder="Color"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={this.state.colorList}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.color == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Dropdown
          className="product__selector"
          name="voltage"
          value={
            this.props.company === 'Aurender America Inc.' &&
            this.state.voltage === ''
              ? '120V'
              : this.state.voltage
          }
          placeholder="Voltage"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={
            this.state.voltageReverse
              ? this.state.voltageList.reverse()
              : this.state.voltageList
          }
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.voltage == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Dropdown
          className="product__selector"
          name="hdd"
          value={this.state.hdd}
          placeholder="Storage"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={this.state.hddList}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.hdd == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Dropdown
          className="product__selector"
          name="ssd"
          value={this.state.ssd}
          placeholder="SSD"
          fluid
          search
          selection
          style={{ width: '19%' }}
          options={this.state.ssdList}
          onChange={this.dropDownOnChangeHandler}
          onFocus={this.dropDownOnFocusHandler}
          onClose={this.dropDownOnCloseHandler}
          error={
            this.state.ssd == '' && this.state.isWrongSelect ? true : false
          }
        />
        <Button
          style={{ width: '5%', marginLeft: '10px', padding: '0px' }}
          onClick={this.resetButtonOnClickHandler}
        >
          X
        </Button>
      </div>
    );
  }
}

ProductSuggestDropDown.PropTypes = propTypes;
ProductSuggestDropDown.defaultProps = defaultProps;
