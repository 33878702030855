import React from 'react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

export default (props) => {
  const onChange = (value) => {
    props.input.onChange(!props.input.value);
  };
  return (
    <Checkbox
      {...props}
      onChange={onChange}
      checked={props.input.value}
      label="Test"
    />
  );
};
