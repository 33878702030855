import React, { Component } from 'react';
import {
  Field,
  Fields,
  reduxForm,
  getFormValues,
  isDirty,
  formValues,
  change,
} from 'redux-form';
import { MoneyInput, ColumnLabel } from './presenters/customComponents';
import { Container, Grid, Header } from 'semantic-ui-react';

import SalesOrderType from './presenters/SalesOrderType';
import TermsofDelivery from './presenters/TermsofDelivery';

import Product from './containers/Product';
import Note from './containers/Note';
import CustomerContainer from './containers/CustomerContainer';
import SalesOrderNumber from './presenters/SalesOrderNumber';
import SalesOrderDate from './presenters/SalesOrderDate';
import TotalContainer from './containers/TotalContainer';
import ButtonContainer from './containers/ButtonContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setSalesOrderInitailReduxFormValue,
  getSalesOrderFormData,
} from '../../action/salesOrder';
import {
  setInitialProductOrderList,
  getSalesOrderItems,
} from '../../action/product';
import { FingurLoader } from '../General/FingurLoader';
import {
  getDropShipContact,
  initCustomerAdress,
  initSelectedAddress,
  resetDropShipContact,
} from '../../action/customer';
import SalesOrderTestCheckBox from './presenters/SalesOrderTestCheckBox';
import SalesOrderDropShipCheckBox from './presenters/SalesOrderDropShipCheckBox';
import DeliveryMethodContainer from './containers/DeliveryMethodContainer';
import ForwarderDropdown from './presenters/ForwarderDropdown';

import formatDate from '../../util/dateToYYYY-MM-DD';

import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'; // ES6
import '../../styles/FadeInOut.css';
import '../../styles/SalesOrderBottom.css';

const validate = (values) => {
  const errors = {};

  if (!values.SOIssueDate) {
    errors.SOIssueDate = 'Required';
  }
  if (!values.SOType) {
    errors.SOType = 'Required';
  }
  if (!values.billingAccountID) {
    errors.AccountID = 'Required';
  }
  if (!values.shippingAccountID) {
    errors.AccountID = 'Required';
  }
  // if (!values.BillTo) {
  //   errors.BillTo = 'Required'
  // }
  // if (!values.ShipTo) {
  //   errors.ShipTo = 'Required'
  // }
  if (!values.bill_to) {
    errors.bill_to = 'Required';
  }
  if (!values.ship_to) {
    errors.ship_to = 'Required';
  }
  if (!values.TermsOfDelivery) {
    errors.TermsOfDelivery = 'Required';
  }
  if (!values.DeliveryMethod) {
    errors.DeliveryMethod = 'Required';
  }

  return errors;
};

const CustomerC = (fields) => <CustomerContainer fields={fields} />;
const salesOrderFormInitailValue = {
  Adjustment: 0,
  ShippingCharge: 0,
  SOIssueDate: formatDate(new Date()),
  Test: window.location.host.indexOf('localhost') > -1 ? true : false,
  DropShip: false,
  Note: null,
};

const forwarderEnableStyle = {
  transform: 'translateY(10px)',
  transition: 'transform 0.5s cubic-bezier(0.18, 0.55, 0.2, 1)',
};

class NewSalesOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevDeliveryMethod: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { formValues, dispatch } = this.props;
    const prevDropShip = prevProps.formValues?.DropShip;
    const currentDropShip = formValues?.DropShip;

    // DropShip이 true에서 false로 변경될 때 Note 초기화
    if (prevDropShip && !currentDropShip && formValues?.Note) {
      dispatch(change('SalesOrder', 'Note', ''));
    }

    if (
      this.props.isEdit &&
      !prevProps.initialValues &&
      this.props.initialValues
    ) {
      const dropShipContactId = this.props.initialValues.dropship_contact_id;
      const accountId = this.props.initialValues.AccountID;
      if (dropShipContactId && !this.props.dropShipContact) {
        this.props.getDropShipContact(accountId, dropShipContactId);
      }
    }
  }

  componentDidMount() {
    this.props.resetDropShipContact();
    this.props.setInitialProductOrderList();
    if (this.props.isEdit) {
      const SOID = this.props.match.params.SOID;
      this.props.getSalesOrderFormData(SOID);
      this.props.getSalesOrderItems(SOID);
    } else {
      this.props.setSalesOrderInitailReduxFormValue(salesOrderFormInitailValue);
      this.props.initCustomerAdress();
      this.props.initSelectedAddress();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      prevDeliveryMethod: this.props.deliveryMethod,
    });
  }

  render() {
    const {
      handleSubmit,
      setInitailFormValue,
      initialValues,
      isSalesOrderFormDataLoading,
      deliveryMethod,
      salesOrderType,
      formValues,
      dropShipContact,
    } = this.props;

    let isForwarder = deliveryMethod === 3 ? true : false;
    let bottomRowStyle = isForwarder
      ? forwarderEnableStyle
      : this.state.prevDeliveryMethod === 3 && deliveryMethod
        ? {
            animationDuration: '0.5s',
            animationName: 'booster',
            animationIterationCount: '1',
            animationDirection: 'alternate',
          }
        : {};

    const isDropShipChecked = formValues && formValues.DropShip;

    return (
      <React.Fragment>
        {isSalesOrderFormDataLoading ? (
          <FingurLoader />
        ) : (
          <form
            onSubmit={handleSubmit}
            onKeyUp={(e) => {
              const keyCode = e.keyCode || e.which;
              if (keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
            onKeyPress={(e) => {
              const keyCode = e.keyCode || e.which;
              if (keyCode === 13) {
                e.preventDefault();
                return false;
              }
            }}
          >
            {
              <div>
                <Container>
                  <Header
                    as="h1"
                    dividing
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '40px',
                      }}
                    >
                      {this.props.isEdit
                        ? 'Edit Sales Order'
                        : 'New Sales Order'}
                      {!this.props.isEdit && (
                        <Field
                          name="DropShip"
                          component={SalesOrderDropShipCheckBox}
                        />
                      )}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Field name="Test" component={SalesOrderTestCheckBox} />
                    </div>
                  </Header>
                  <br />
                  <Grid columns="equal" stackable>
                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Zoho SO Number" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="zoho_so_number"
                          component={SalesOrderNumber}
                          labelName="Sales Order Type"
                        />
                      </Grid.Column>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Reference Number" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="reference_number"
                          component={SalesOrderNumber}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="SalesOrderType" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="SOType"
                          component={SalesOrderType}
                          labelName="Sales Order Type"
                        />
                      </Grid.Column>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Sales Order Date" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field name="SOIssueDate" component={SalesOrderDate} />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="top">
                        <ColumnLabel
                          text="Customer"
                          style={{
                            padding: '8px 0',
                            lineHeight: '1em',
                          }}
                        />
                      </Grid.Column>
                      {/* <CustomerContainer /> */}
                      <Fields
                        names={[
                          'billingAccountID',
                          'shippingAccountID',
                          'bill_to',
                          'ship_to',
                          'DropShipContactId',
                        ]}
                        component={CustomerC}
                      />
                    </Grid.Row>
                    <Grid.Row stretched>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Terms Of Delivery" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="TermsOfDelivery"
                          labelName="Terms of Delivery"
                          component={TermsofDelivery}
                        />
                      </Grid.Column>
                      <Grid.Column width={3} verticalAlign="middle">
                        <ColumnLabel text="Delivery Method" />
                      </Grid.Column>
                      <Grid.Column width={5} verticalAlign="middle">
                        <Field
                          name="DeliveryMethod"
                          labelName="Delivery Method"
                          component={DeliveryMethodContainer}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <ReactCSSTransitionGroup
                      transitionEnter={true}
                      transitionLeave={true}
                      transitionAppear={true}
                      transitionLeaveTimeout={500}
                      style={{
                        padding: 0,
                        width: '100%',
                        margin: 0,
                      }}
                      className="ui grid"
                      transitionName="Opacity"
                    >
                      {isForwarder ? (
                        <Grid.Row className="stretched row" stretched>
                          <Grid.Column
                            class="middle aligned three wide column"
                            width={3}
                            verticalAlign="middle"
                          ></Grid.Column>
                          <Grid.Column
                            class="middle aligned five wide column"
                            width={5}
                            verticalAlign="middle"
                          ></Grid.Column>
                          <Grid.Column
                            class="middle aligned three wide column"
                            width={3}
                            verticalAlign="middle"
                          >
                            <ColumnLabel text="Forwarder" />
                          </Grid.Column>
                          <Grid.Column
                            class="middle aligned five wide column"
                            width={5}
                            verticalAlign="middle"
                          >
                            <Field
                              name="Forwarder"
                              labelName="Forwarder"
                              component={ForwarderDropdown}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}
                    </ReactCSSTransitionGroup>

                    <div style={bottomRowStyle} className="ui grid">
                      <Product />
                      {isDropShipChecked && <Note />}
                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Adjustment" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <Field
                            name="Adjustment"
                            component={MoneyInput}
                            prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                            selectedCustomer={this.props.selectedCustomer}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Shipping Charge" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <Field
                            name="ShippingCharge"
                            component={MoneyInput}
                            prefix={this.props.currency == 'KRW' ? '₩' : '$'}
                            selectedCustomer={this.props.selectedCustomer}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row stretched>
                        <Grid.Column
                          width={10}
                          verticalAlign="middle"
                        ></Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <ColumnLabel text="Total" />
                        </Grid.Column>
                        <Grid.Column width={3} verticalAlign="middle">
                          <TotalContainer salesOrderType={salesOrderType} />
                        </Grid.Column>
                      </Grid.Row>
                      <ButtonContainer
                        isEdit={this.props.isEdit}
                        SOID={this.props.match.params.SOID}
                        salesOrderType={salesOrderType}
                      />
                    </div>
                  </Grid>
                </Container>
              </div>
            }
          </form>
        )}
      </React.Fragment>
    );
  }
}

export default NewSalesOrder = connect(
  (state) => ({
    initialValues: state.salesOrderFormValue,
    enableReinitialize: true,
    selectedCustomer: state.selectedCustomer.billingCustomer,
    currency:
      state.selectedCustomer.billingCustomer.Currency != undefined
        ? state.selectedCustomer.billingCustomer.Currency.trim()
        : 'USD',
    deliveryMethod: !!getFormValues('SalesOrder')(state)
      ? getFormValues('SalesOrder')(state).DeliveryMethod
      : null,
    salesOrderType: !!getFormValues('SalesOrder')(state)
      ? getFormValues('SalesOrder')(state).SOType
      : null,
    shouldValidate: () => isDirty('SalesOrder')(state),
    formValues: getFormValues('SalesOrder')(state),
    dropShipContact: state.dropShipContact,
  }),
  (dispatch) => ({
    setSalesOrderInitailReduxFormValue: bindActionCreators(
      setSalesOrderInitailReduxFormValue,
      dispatch
    ),
    getSalesOrderFormData: bindActionCreators(getSalesOrderFormData, dispatch),
    getSalesOrderItems: bindActionCreators(getSalesOrderItems, dispatch),
    initCustomerAdress: bindActionCreators(initCustomerAdress, dispatch),
    setInitialProductOrderList: bindActionCreators(
      setInitialProductOrderList,
      dispatch
    ),
    initSelectedAddress: bindActionCreators(initSelectedAddress, dispatch),
    getDropShipContact: bindActionCreators(getDropShipContact, dispatch),
    resetDropShipContact: bindActionCreators(resetDropShipContact, dispatch),
    change: bindActionCreators(change, dispatch),
  })
)(
  reduxForm({
    validate,
    form: 'SalesOrder',
  })(NewSalesOrder)
);
