import React from 'react';
import { Grid, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';

const renderTextArea = ({ input }) => {
  const handleKeyPress = (e) => {
    e.stopPropagation(); // 이벤트 버블링 방지
  };

  return (
    <Form>
      <textarea
        {...input}
        style={{ height: '150px', width: '100%' }}
        onKeyPress={handleKeyPress}
      />
    </Form>
  );
};

const Note = () => {
  return (
    <Grid.Row stretched style={{ marginTop: 30 }}>
      <Grid.Column width={3}>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <h3
            style={{
              color: 'black',
              whiteSpace: 'nowrap',
              flex: 1,
              margin: 0,
            }}
          >
            Note
          </h3>
        </div>
      </Grid.Column>
      <Grid.Column width={6}>
        <Field name="Note" component={renderTextArea} />
      </Grid.Column>
    </Grid.Row>
  );
};

export default Note;
